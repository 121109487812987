
.Navbar_container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    max-width: 100%;
    height: 84px;
    background: #fff;
    border-radius: 0px;
    padding-left: 1rem;
    padding-right: 1rem;
    position:fixed;
    z-index: 100;
    width:100%;
    top:0;
    overflow-x: clip;
    box-shadow: 0px 0px 6px #888;
    
}
.logo{
    width: 80px;
    height: 80px;
    object-fit: contain;
    cursor: pointer;
}
.Navbar_col{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 30%;
    height: 100%;
    
}
.Navbar_menuItem{
    cursor: pointer;

}
