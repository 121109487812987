:root{
  --primary-light: #f53254;
  --primary-main: #eb0029;
  --primary-dark: #b90827;
  --primary-contrastText: #fff;
  --secondary-light: #FFFFFF;
  --secondary-main: #FFFFFF;
  --secondary-dark: #FFFFFF;
  --secondary-contrastText: #fff;
  --background-paper: #fff;
  --background-default: #F9F9F9;
}
body {
  overflow-x: hidden;
  padding-top: 5rem;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.flex{
  display:'flex';
}
.row{
  display: flex;
  flex-direction: row;
}
.col{
  display:flex;
  flex-direction: column;
}
.aligncenter{
  align-items: center;
}
.justifybetween{
  justify-content: space-between;
}
.justifycenter{
  justify-content: center;
}
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #eee;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
  
}
