.demo-section {
    /* padding: 2rem; */
    text-align: center;
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .demo-section h2 {
    margin-bottom: 1rem;
    font-size: 40px;
  }
  .demo-section p {
    margin-bottom: 1rem;
    font-size: 20px;
    
  }
  .demo-section button {
    padding: 0.5rem 2rem;
    font-size: 1rem;
    color: #fff;
    background-color: var(--primary-main);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 1rem;
    transition: 0.5s;
    margin-top: 1rem;
  }
  .demo-section button:hover {
    background-color:var(--primary-light);
    transition: 0.5s;
  }

  @keyframes highlight {
    from { background-color: transparent; }
    to { background-color: rgb(255, 255, 255); }
  }
  
  .highlight-row {
    animation: highlight 2s ease-in-out;
  }
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes highlightAnimation {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  .border-highlight {
    box-shadow: 1px 1px 10px #FFD700; /* Optional: adds a glow effect */
    content: '';
    position: absolute;
    border: 2px solid #FFD700; /* Highlight color */
    box-sizing: border-box;
    animation: highlightAnimation 1s infinite alternate;
  
  }
  
  
  .data-flip {
    transition: transform 0.5s ease-in-out;
    
  }
  
  .data-rotated {
    transform: rotateY(180deg);
    transition: transform 0.5s ease-in-out;
  }

  .data-flip2 {    
    transform: rotateY(0deg) ; 
    transition: transform 0.5s ease-in-out ;
  }
  
  .data-rotated2 {
    transform: rotateY(180deg) translateZ(-10px);
    transition: transform 0.5s ease-in-out;
  }
