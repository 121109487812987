.cta-section {
    padding: 2rem;
    padding-top: 3rem;
    text-align: center;
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    
  }
  .cta-section h2 {
    margin-bottom: 1rem;
    font-size: 40px;
  }
  .cta-section p {
    margin-bottom: 1rem;
    font-size: 20px;
  }
  button {
    min-width: 150px;
    padding: 0.5rem 2rem;
    font-size: 1rem;
    color: #fff;
    background-color: var(--primary-main);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 1rem;
    transition: 0.5s;
  }
  button:hover {
    background-color:var(--primary-light);
    transition: 0.5s;
  }
  .CTA_LEFT {
    width:50%;
  }
  .CTA_RIGHT {
    width:50%;
  }
  
  @media screen and (max-width: 768px) {
    .cta-section {
      padding: 0rem;
      text-align: center;
      width: 100%;
      min-height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      
    }
    .CTA_LEFT {
      width:100%;
    }
    .CTA_RIGHT {
      width:100%;
    }
    .cta-section h2 {
      margin-bottom: 1rem;
      font-size: 32px;
    }
    .cta-section p {
      margin-bottom: 1rem;
      font-size: 20px;
    }
  }