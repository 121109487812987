/* ScanningEffect.css */
.scanning-container {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  z-index: 2;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255, 255, 0, 0.55) 50%,
    transparent 100%
  );
  animation: scanningAnimation 2s linear infinite ;
  
}


@keyframes scanningAnimation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
